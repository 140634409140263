import { APIFilter } from '@/utils/api'

export default {
  async selectLoadPage (Vue, idpresupuestocli) {
    const apiCalls = []
    const apiFilterPresupuesto = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCalls.push({ name: 'selectPresupuestocli', method: 'presupuestocli.select', params: { filter: apiFilterPresupuesto } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectLookups (Vue) {
    const apiFilterEstadoActivo = new APIFilter()
      .addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({ name: 'selectPeriodoRevision', method: 'periodoRevision.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTactuacion', method: 'tactuacion.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectPresupuestocliMant (Vue, filter, search, sorter, page, idpresupuestocli) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
      .addExact('idpresupuestocli', idpresupuestocli)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.presupuestocliMant.searchFilter(search))
    }
    if (filter.periodoRevision.value && filter.periodoRevision.value.length > 0) {
      apiFilter.addIn(filter.periodoRevision.field, filter.periodoRevision.value)
    }
    if (filter.tactuacion.value && filter.tactuacion.value.length > 0) {
      apiFilter.addIn(filter.tactuacion.field, filter.tactuacion.value)
    }
    const resp = await Vue.$api.call(
      'presupuestocliMant.select',
      {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectPresupuestocliMantRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idpresupuestocli_mant', pks)
    const resp = await Vue.$api.call('presupuestocliMant.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deletePresupuestocliMant (Vue, idpresupuestocliMant) {
    await Vue.$api.call('presupuestocliMant.delete', { idpresupuestocli_mant: idpresupuestocliMant })
  },
}
